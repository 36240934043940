<template>
    <page>
        <page-head></page-head>
        <page-body>
            <div class="text-center align-center inline-block">
                <a class="place-self-center cursor-pointer" @click="resetAllCache()">
                    <img class="" src="https://portalgames.az/uploads/photos/setting/red-button.png" alt="">
                </a>
                <span class="text-xl font-bold">Delete Whole Site !</span>
            </div>
        </page-body>
    </page>
</template>

<script>
/*
 * Import Components
 * */

const translateKey = 'crm.Setting';
import {mapActions, mapState} from 'vuex'

export default {
    name: "SensitiveIndex",
    methods: {
        resetAllCache(){
            this.alert('Əminsən ?').then(
                r => {
                    window.open("https://portalgames.az/rest/artisan").focus()
                }
            );
        }
    },
}
</script>

<style scoped>

</style>
